<template>
  <div class="step-list">
    <div>
      <p v-if="status === 'new'">
        Select the journals you want to enter today.
      </p>
      <p v-if="status === 'completed'">
        Select your completed journal to celebrate them.
      </p>
    </div>

    <van-skeleton :row="10" :loading="!stepsLoaded" />

    <StepListItem v-for="step in steps" :step="step" :key="step.id" />

    <p v-if="stepsLoaded && steps.length <= 0">
      <span v-if="status === 'new'">
        You have completed all your steps today. Good job!
      </span>
      <span v-if="status === 'completed'">
        Yet to complete a step today.
      </span>
    </p>
  </div>
</template>

<script>
import StepListItem from "@/components/StepListItem";
import "@vant/touch-emulator";
import { StepProvider } from "@/providers";

export default {
  name: "StepList",
  components: { StepListItem },

  props: {
    status: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      steps: [],
      stepsLoaded: false
    };
  },

  created() {
    window.Event.listen("JournalingCompleted", this.journalingCompleted);
  },

  mounted() {
    this.getSteps();
  },

  methods: {
    journalingCompleted() {
      this.getSteps();
    },

    getSteps() {
      const self = this;
      StepProvider.getSteps(this.status).then(response => {
        self.stepsLoaded = true;
        self.steps = response;
      });
    }
  }
};
</script>

<style scoped></style>
