<template>
  <div id="login">
    <Logo />

    <van-loading type="spinner" color="#f44" v-if="sendingOTP" />

    <van-row>
      <van-col span="20" offset="2">
        <van-row type="flex" justify="space-around" align="center">
          <vue-country-code
            style="height: 50px"
            @onSelect="onSelectCountryCode"
            :preferredCountries="['in', 'us', 'gb']"
          />
          <van-field
            v-model="mobile"
            type="tel"
            placeholder="Mobile No"
            maxlength="10"
            size="large"
            :error-message="mobileNoFieldError"
            border
          />
        </van-row>
      </van-col>
    </van-row>

    <van-row>
      <van-col span="20" offset="2">
        <van-button
          type="primary"
          round
          :text="sendingOTP ? 'Sending OTP' : 'Get OTP'"
          size="large"
          :disabled="sendingOTP"
          @click="handleGetOTP"
        />
      </van-col>
    </van-row>

    <a class="email-login" href="" @click="gotoEmailLogin">
      Login using email address
    </a>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { router } from "@/router";

import Logo from "@/components/Logo";
import { Login } from "@/helpers";

export default {
  name: "DoLoginViaSMS",
  components: { Logo },

  props: {
    mode: String
  },

  data() {
    return {
      countryCode: null,
      mobile: null,

      nativeBridge: null,
      isNativeBridgeObtained: false,

      mobileNoFieldError: null
    };
  },

  computed: {
    ...mapState({
      sendingOTP: state => state.account.status.sendingOTP
    }),

    ...mapState("account", ["user"])
  },

  watch: {
    async mobile(value) {
      if (!value) {
        return;
      }

      if (value.length > 0) {
        this.mobileNoFieldError = "";
      }
    }
  },

  methods: {
    ...mapActions("account", ["sendOTP", "logout"]),

    gotoEmailLogin() {
      Login.gotoEmailLogin();
    },

    onSelectCountryCode({ dialCode: countryCode }) {
      this.countryCode = countryCode;
    },

    async handleGetOTP() {
      if (!this.mobile || this.mobile.length === 0) {
        this.mobileNoFieldError = "Mobile No required";

        return;
      } else if (this.countryCode === "91") {
        if (this.mobile.length < 10 || this.mobile.length > 10) {
          this.mobileNoFieldError = "Invalid mobile no";

          return;
        }
      }

      await this.sendOTP({
        mobile: this.countryCode + this.mobile
      });
      router.push({
        name: "verify-otp",
        params: {
          countryCode: this.countryCode,
          mobile: this.mobile,
          appId: Login.getAppId()
        }
      });
    }
  }
};
</script>

<style scoped>
.van-row {
  margin-bottom: 50px;
}

.van-row input {
  border-radius: 10em;
  height: 50px;
  line-height: 48px;
  padding-left: 10px;
  width: 97%;
}

/deep/ .vue-country-select .dropdown {
  height: 70%;
}

.van-loading {
  z-index: 999;
}

.email-login {
  color: #07c160;
}

.email-login:hover {
  text-decoration: underline;
}
</style>
