import { api } from "@/api";

/**
 * Do a Journal.
 *
 * @param stepId
 * @param response
 * @returns {AxiosPromise<any>}
 */
async function doJournal(stepId, response) {
  return api.post(`journal/${stepId}`, {
    response
  });
}

export const JournalProvider = {
  doJournal
};
