import { CurrentUser } from "@/helpers";
import TeamDashboardPage from "@/pages/TeamDashboardPage";
import { AuthProvider } from "@/providers";
import Vue from "vue";
import Router from "vue-router";
import HomePage from "./pages/HomePage.vue";
import LoginViaSMSPage from "./pages/LoginViaSMSPage.vue";
import VerifyOtp from "./pages/VerifyOtp.vue";
import LoginViaEmailPage from "./pages/LoginViaEmailPage.vue";
import VerifyEmailOtpPage from "./pages/VerifyEmailOtpPage.vue";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login-via-email-page",
      component: LoginViaEmailPage
    },
    {
      path: "/login/sms",
      name: "login-via-sms-page",
      component: LoginViaSMSPage
    },
    {
      path: "/verify/otp",
      name: "verify-otp",
      component: VerifyOtp
    },
    {
      path: "/verify/email-otp",
      name: "verify-email-otp",
      component: VerifyEmailOtpPage
    },
    {
      path: "/",
      name: "home-page",
      component: HomePage
    },
    {
      path: "/team-dashboard",
      name: "team-dashboard",
      component: TeamDashboardPage
    },
    {
      path: "/journal/:stepId",
      name: "journal-page",
      props: true,
      component: () =>
        import(/* webpackChunkName: "journal-page" */ "./pages/JournalPage.vue")
    },
    {
      path: "/about",
      name: "about-page",
      component: () =>
        import(/* webpackChunkName: "about-page" */ "./pages/AboutPage.vue")
    },
    {
      path: "/profile",
      name: "profile-page",
      component: () =>
        import(/* webpackChunkName: "profile-page" */ "./pages/ProfilePage.vue")
    },
    {
      path: "/task/journal/:taskId",
      name: "task-journal-page",
      props: true,
      component: () =>
        import(/* webpackChunkName: "task-journal-page" */ "./pages/TaskJournalPage.vue")
    },
    {
      path: "*",
      redirect: "/"
    }
  ]
});

/**
 * Redirect to login page if not logged in and trying to access a restricted page.
 */
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/verify/email-otp",
    "/login/sms",
    "/verify/otp"
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = CurrentUser.loggedIn();

  if (!authRequired) {
    // Show login or register pages without further checks.
    return next();
  }

  if (!loggedIn) {
    // If the user is not logged in then redirect to login page.
    return next("/login");
  }

  // If the user is logged in and the request is not a login/register page,
  // then check for redirects.
  AuthProvider.getMagiqsparkRedirectUrl().then(response => {
    if (response.data && false === response.data.redirect) {
      next();
    } else {
      window.location.href = response.data.redirect;
    }
  });
});
